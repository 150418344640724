<template>
	<minePage :info="{}" barTitle="我的订单" :barOptions="barOptions" :cellOptions="cellOptions" @barChange="barChange" @cellChange="cellChange"></minePage>
</template>

<script>
import minePage from '@components/minePage';
export default {
	name: 'mine',
	components: { minePage },
	data() {
		return {
			barOptions: [
				{
					label: '共享设置',
					icon: require('@/icons/imgs/workManager-mine-bar-1.png'),
					badge: 0,
					page: 'shareSetting',
          key:'inPool',
				},
				{
					label: '待审批',
					icon: require('@/icons/imgs/workManager-mine-bar-2.png'),
					badge: 0,
					page: 'approveWait',
          key:'unexamined',
        },
				{
					label: '共享概览',
					icon: require('@/icons/imgs/workManager-mine-bar-3.png'),
					badge: 0,
					page: 'shareOverview',
          key:'',
        },
				{
					label: '共享收益',
					icon: require('@/icons/imgs/workManager-mine-bar-4.png'),
					badge: 0,
					page: 'shareEarnings',
          key:'',
        }
			],
			cellOptions: [
				{
					label: '审批记录',
					icon: require('@/icons/imgs/workManager-mine-cell-1.png'),
					page: 'approveRecord'
				},
				{
					label: '借用记录',
					icon: require('@/icons/imgs/workManager-mine-cell-2.png'),
					page: 'borrowRecord'
				},
				{
					label: '报障记录',
					icon: require('@/icons/imgs/workManager-mine-cell-3.png'),
					page: 'breakdownRecord'
				},
				{
					label: '收益记录',
					icon: require('@/icons/imgs/workManager-mine-cell-4.png'),
					page: 'incomeRecord'
				},
				{
					label: '共享记录',
					icon: require('@/icons/imgs/workManager-mine-cell-5.png'),
					page: 'shareRecord'
				}
			]
		};
	},
	created() {},
	mounted() {
    this.getData();
    this.addLocalStorageEventByKey('allDeviceUpdate', this.getData)
  },
  beforeDestroy() {
    this.removeLocalStorageEvent(this.getData)
  },
	methods: {
    getData:function() {
      let getCount = () => {
        return new Promise(resolve => {
          let tenantIds = ''
          if (this.$valueIsExist(this.query, 'tenantIds')) {
            tenantIds = this.query['tenantIds']
          }
          let query = {
            startTime: '',
            endTime: new Date().getTime(),
            isTenantIdChild: 1,
            isCategoryIdChild: 1,
            tenantIds: tenantIds
          }

          this.$api.deviceWorkManager.shareCount2(query).then(res => {
            let info = {}
            if (this.$valueIsExist(res, 'data')) {
              info = res['data']
            }
            resolve({ result: true, info: info })
          }).catch(e => {
            resolve({ result: true, info: {} })
          })
        })
      }

      let updateList = (list, info) => {
        for (let i = 0; i < list.length; i++) {
          let data = list[i]
          if (data.key == '') {
            continue
          }
          if (this.$valueIsExist(info, data.key)) {
            data.badge = info[data.key]
          } else {
            data.badge = 0
          }
        }
      }

      this.loading = true
      getCount().then(res => {
        updateList(this.barOptions, res.info)
        this.loading = false
      })
    },
		barChange(e) {
			this.$push('/workManager/order/' + e.page);
		},
		cellChange(e) {
			this.$push('/workManager/record/' + e.page);
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
</style>
